import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  getCustomUIStates,
  getLocale,
} from '../../../Store/Slice/UI/selectors';
import { TTemplateElemContext } from '../../../Components/Page/Components/TemplateElements/Components/TemplateElement/types';
import { getMe, getUsers } from '../../../Store/Slice/Users/selectors';
import { constructMutatorContexts } from '../Helpers/constructMutatorContexts';
import { getGlobalVariables } from '../../../Store/Slice/Site/selectors';
import { getWgGroups } from '../../../Store/Slice/WorkGroups/selectors';
import {
  getCnDocuments,
  getCnFolders,
} from '../../../Store/Slice/Content/selectors';
import { getEvEvents } from '../../../Store/Slice/Events/selectors';

export const useMutatorSelectors = (current?: TTemplateElemContext) => {
  return constructMutatorContexts(current, {
    location: useLocation(),
    params: useParams(),
    locale: useSelector(getLocale),
    states: useSelector(getCustomUIStates),
    myUser: useSelector(getMe),
    variables: useSelector(getGlobalVariables),
    users: useSelector(getUsers),
    wgGroups: useSelector(getWgGroups),
    cnDocuments: useSelector(getCnDocuments),
    cnFolders: useSelector(getCnFolders),
    evEvents: useSelector(getEvEvents),
  });
};
