import _ from 'lodash';
import { TBaseDataObject } from '../../../../types';
import { TStTemplate, TStTemplateElem } from '../../../../Slice/Site/types';
import { SITE } from './constants';

class ContextSelectors {
  private getSelectors(mutations: string[]) {
    return _.uniq(
      _.flatten(
        mutations.map((mutation) =>
          _.reduce(
            SITE.SELECTORS,
            (r, v, k) => {
              const checks = v.map((exp) => exp.test(mutation));
              if (checks.includes(true)) return [...r, k];
              return [...r];
            },
            [] as string[]
          )
        )
      )
    );
  }

  private checkArray(srcArray: any[]): any[] {
    return srcArray.map((item) => {
      if (_.isObject(item)) {
        if (_.isArray(item)) {
          return this.checkArray(item);
        }
        return this.checkObject(item as TBaseDataObject);
      }
      return item;
    });
  }

  private checkObject(srcObject: TBaseDataObject): TBaseDataObject {
    if (srcObject.tag && _.isString(srcObject.tag))
      return this.element(srcObject as TStTemplateElem);
    return _.reduce(
      srcObject,
      (r, v, k) => {
        if (_.isObject(v)) {
          if (_.isArray(v)) return { ...r, [k]: this.checkArray(v) };
          return { ...r, [k]: this.checkObject(v) };
        }
        return { ...r, [k]: v };
      },
      {}
    );
  }

  private applySelectors(element: TStTemplateElem) {
    const paramsString = JSON.stringify(
      element.tag === 'Template' ? element : _.omit(element, ['children'])
    );
    const exp = /<%=?((?!%>).)*%>/gm;
    const paramsMutations = paramsString.match(exp) || [];

    const childrenStrings = element.children
      ? _.isString(element.children)
        ? [element.children]
        : _.isArray(element.children)
        ? element.children.filter((child) => _.isString(child))
        : []
      : [];

    const childrenMutations = childrenStrings.length
      ? JSON.stringify(childrenStrings).match(exp)
      : [];

    const mutations = [...paramsMutations, ...(childrenMutations || [])];
    const selectors = this.getSelectors(mutations);
    const variables = element.variables
      ? _.isObject(element.variables) && !_.isArray(element.variables)
        ? this.checkObject(element.variables as TBaseDataObject)
        : element.variables
      : undefined;

    const elementNew = {
      ...element,
      ...(variables ? { variables } : {}),
      mutationSchema: {
        selectors,
        hasMutations: !!mutations.length,
      },
    };

    return elementNew;
  }

  element(element: TStTemplateElem): TStTemplateElem {
    const checkElem = this.applySelectors(element);
    if (_.isObject(checkElem.children)) {
      if (_.isArray(checkElem.children)) {
        const children = checkElem.children.map((child) => {
          if (_.isString(child)) return child;
          return this.element(child);
        });
        return { ...checkElem, children };
      }
      return {
        ...checkElem,
        children: this.element(checkElem.children as TStTemplateElem),
      };
    }
    return checkElem;
  }

  template(template: TStTemplate) {
    if (_.isString(template.template)) return template;
    if (_.isArray(template.template)) {
      const elements = template.template.map((element) =>
        this.element(element)
      );
      return {
        ...template,
        template: elements,
      };
    }
    return {
      ...template,
      template: this.element(template.template),
    };
  }
}

export default ContextSelectors;
