import { TFetchResponseError, TSiteActionProps } from '../Store/types';

/** Адрес сервера  API */
export const API_URL_PREFIX =
  document.location.port === '3000'
    ? `http://${document.location.hostname}:1337`
    : '';

export const API_ENDPOINTS = {
  init: '/api/st-init',
  login: '/api/auth/local',
  register: '/api/auth/local/register',
  'forgot-password': '/api/auth/forgot-password',
  'reset-password': '/api/auth/reset-password',
};

/** Локаль по-умолчанию */
export const LOCALE_DEFAULT = 'ru';

/** Экшны при ошибках запросов */
export const fetchErrorActions: (Partial<TFetchResponseError> & {
  actions: TSiteActionProps[];
})[] = [
  {
    message: 'Missing or invalid credentials',
    status: '401',
    actions: [
      {
        name: 'logOut',
      },
    ],
  },
];
