import { schema } from 'normalizr';
import { wgGroup } from './wgGroup';
import { evEvent } from './evEvent';

export const user = (depth?: number): schema.Entity => {
  if (!depth) return new schema.Entity('users');
  return new schema.Entity('users', {
    membershipGroups: {
      others: [wgGroup(depth - 1)],
      self: [wgGroup(depth - 1)],
      both: [wgGroup(depth - 1)],
    },
    membershipEvents: {
      others: [evEvent(depth - 1)],
      self: [evEvent(depth - 1)],
      both: [evEvent(depth - 1)],
    },
    alerts: [new schema.Entity('stAlerts')],
  });
};
