import { denormalize } from 'normalizr';
import { RootState } from '../..';
import { TSelectorArg } from '../../types';
import filterStoreItems from '../Helpers/filterStoreItems';
import { evEvent } from '../../NormSchema/evEvent';
import _ from 'lodash';

export const getEvEvents = (state: RootState, args?: TSelectorArg[]) => {
  const collection = state.sliceEvents.evEvents;
  const filtered = args ? filterStoreItems(collection, args) : collection;
  if (!filtered) return;
  const wgGroupsNr = state.sliceWorkGroups.wgGroups;
  const usersNr = state.sliceUsers.users;
  const evEvents = denormalize(Object.values(filtered), [evEvent(1)], {
    wgGroups: wgGroupsNr,
    users: usersNr,
  });
  return _.reduce(evEvents, (r, v) => ({ ...r, [v.id]: v }), {});
};
