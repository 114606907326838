import { TCustomInputImage, TCustomInputImageContext } from './types';
import { ChangeEventHandler, useEffect, useState } from 'react';
import { constructSrc } from './Helpers/constructSrc';
import { Area } from 'react-easy-crop/types';
import { getCroppedImg } from './Helpers/getCroppedImg';
import { getResizedImage } from './Helpers/getResizedImage';
import { TFormInputImage } from '../CustomForm/types';
import TemplateElements from '../../../../../..';
import { TTemplateElemContext } from '../../../../types';

const CustomInputImage: React.FC<TCustomInputImage> = ({
  name,
  contexts,
  crop,
  childrenPrototype,
}) => {
  const form = contexts?.form;
  const formInput = form?.inputs[
    name || 'unknown-image-input'
  ] as TFormInputImage & {
    touched?: boolean;
    errors?: string[];
  };

  const [src, setSrc] = useState<TCustomInputImageContext['src']>();
  const [res, setRes] = useState<
    { dataURL: string; sizeX: number; sizeY: number; type: string } | undefined
  >(
    formInput?.value
      ? {
          dataURL: formInput?.value,
          sizeX: formInput.size.orig.width,
          sizeY: formInput.size.orig.height,
          type: formInput.file?.type,
        }
      : undefined
  );
  const [croppedAreaPixelsData, setCroppedAreaPixelsData] =
    useState<Area | null>(null);

  const cropInitial = {
    active: false,
    offsetX: 0.5,
    offsetY: 0.5,
    zoom: 1,
    zoomMin: 1,
    rotate: 0,
  };
  const [cropData, setCropData] =
    useState<TCustomInputImageContext['crop']>(cropInitial);

  useEffect(() => {
    if (src?.dataURL && src.file && src.sizeX && src.sizeY && src.type) {
      // TOODO: Решить проблему с минимальным зумом
      // const srcAspectRatio =
      //   src.sizeX > src.sizeY ? src.sizeX / src.sizeY : src.sizeY / src.sizeX;
      if (formInput.crop) {
        setCropData({
          ...cropData,
          // zoom: +srcAspectRatio.toFixed(2),
          // zoomMin: +srcAspectRatio.toFixed(2),
          active: true,
        });
      } else {
        const imgDiv = document.createElement('div');
        imgDiv.setAttribute(
          'style',
          `width: ${formInput?.size.orig.width}px; height: ${formInput?.size.orig.height}px`
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  if (!form) {
    console.log(
      'Tag <InputSimpleContext> must be child of the <FormContext> tag!'
    );
    return null;
  }

  if (!name) {
    console.log('Check required attributes: {name: string}');
    return null;
  }

  const handleCleanup = () => {
    setSrc(undefined);
    setRes(undefined);
    setCropData(cropInitial);
    form.handleChange({ ...formInput, value: undefined });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (!files || !files.length) return;
    setCropData(cropInitial);
    setSrc(undefined);
    setRes(undefined);
    const newFile = files[0];
    const reader = new FileReader();
    reader.readAsDataURL(newFile);
    reader.onload = (eventFile: ProgressEvent<FileReader>) => {
      const newSrcDataUrl = eventFile.target?.result as string;

      if (!formInput.crop) {
        getResizedImage(
          newSrcDataUrl,
          formInput.size.orig.width,
          formInput.size.orig.height,
          formInput.file.type
        ).then((data) => {
          setRes({
            dataURL: data.scaledDataUrl,
            sizeX: data.scaledWidth,
            sizeY: data.scaledHeight,
            type: formInput.file.type,
          });
          form.handleChange({ ...formInput, value: data.scaledDataUrl });
        });
      } else {
        constructSrc(newSrcDataUrl, newFile, setSrc);
      }
    };
  };

  const handleZoom: ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    setCropData({
      ...cropData,
      zoom:
        parseFloat(e.target.value) < cropData.zoomMin
          ? cropData.zoomMin
          : parseFloat(e.target.value),
    });
  };
  const onZoomChange: (z: number) => void = (z) => {
    setCropData({
      ...cropData,
      zoom: z,
    });
  };
  const zoomStep = crop?.zoomStep || 0.1;
  const handleZoomIn: ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    setCropData({
      ...cropData,
      zoom: cropData.zoom + zoomStep,
    });
  };
  const handleZoomOut: ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    setCropData({
      ...cropData,
      zoom:
        cropData.zoom - zoomStep < cropData.zoomMin
          ? cropData.zoomMin
          : cropData.zoom - zoomStep,
    });
  };

  const onRotationChange: (r: number) => void = (r) => {
    setCropData({
      ...cropData,
      rotate: r,
    });
  };

  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixelsData(croppedAreaPixels);
  };

  const handleCrop = () => {
    if (!croppedAreaPixelsData || !src) return;
    getCroppedImg(src.dataURL, croppedAreaPixelsData, cropData.rotate)
      .then((dataUrl) =>
        getResizedImage(
          dataUrl,
          formInput.size.orig.width,
          formInput.size.orig.height,
          formInput.file?.type
        )
      )
      .then((data) => {
        setRes({
          dataURL: data.scaledDataUrl,
          sizeX: data.scaledWidth,
          sizeY: data.scaledHeight,
          type: formInput.file?.type,
        });
        setCropData(cropInitial);
        form.handleChange({
          ...formInput,
          value: data.scaledDataUrl,
        });
      });
  };

  const input: TCustomInputImageContext = {
    name: name || 'unknown-image-input',
    handleChange,
    handleCleanup,
    handleZoom,
    handleZoomIn,
    handleZoomOut,
    handleCrop,
    onRotationChange,
    onCropComplete,
    onZoomChange,
    src,
    crop: cropData,
    res,
    imageProps: formInput?.size.orig,
    fileProps: formInput?.file,
    errors: formInput?.errors,
    required: formInput.required,
  };

  const newContexts: TTemplateElemContext = {
    ...contexts,
    input,
  };

  return (
    <TemplateElements elements={childrenPrototype} contexts={newContexts} />
  );
};

export default CustomInputImage;
