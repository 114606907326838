import { createSlice } from '@reduxjs/toolkit';
import { TSliceContent } from './types';
import { setInitialState } from '../../Saga/Initial/extraActions';

export const initialState: TSliceContent = {
  cnFolders: {},
  cnDocuments: {},
};

export const sliceContent = createSlice({
  name: 'content',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(setInitialState, () => initialState);
  },
  reducers: {},
});

export default sliceContent.reducer;
