import { createSlice } from '@reduxjs/toolkit';
import { TSliceUsers } from './types';
import { setInitialState } from '../../Saga/Initial/extraActions';

export const initialState: TSliceUsers = {
  users: {},
};

export const sliceUsers = createSlice({
  name: 'users',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(setInitialState, () => initialState);
  },
  reducers: {},
});

export default sliceUsers.reducer;
