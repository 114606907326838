import _ from 'lodash';
import { Mutator } from '../../../../../../../../../Library/Mutator/Mutator';
import { TStTemplateElem } from '../../../../../../../../../Store/Slice/Site/types';
export const mutantTextChildren = (
  mutator: Mutator,
  children?: TStTemplateElem['children']
) => {
  mutator.setAttrExclude();
  if (_.isArray(children)) {
    return children.map((child) =>
      _.isString(child) ? mutator.applyStringVariables(child) : child
    );
  }
  if (_.isString(children)) {
    return mutator.applyStringVariables(children);
  }
  return children;
};
