import { schema } from 'normalizr';
import { user } from './user';
import { wgGroup } from './wgGroup';

export const cnDocument = (depth: number): schema.Entity => {
  if (!depth) return new schema.Entity('cnDocuments');
  return new schema.Entity('cnDocuments', {
    ownerPerson: user(depth - 1),
    ownerGroup: wgGroup(depth - 1),
  });
};
