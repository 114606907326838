import {
  Accordion,
  Alert,
  Badge,
  Breadcrumb,
  Button,
  ButtonGroup,
  Card,
  Carousel,
  CloseButton,
  Col,
  Collapse,
  Dropdown,
  DropdownButton,
  DropdownToggle,
  Figure,
  Form,
  InputGroup,
  ListGroup,
  Modal,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
  Overlay,
  Pagination,
  Placeholder,
  ProgressBar,
  Row,
  Spinner,
  Tab,
  Table,
  Tabs,
  Toast,
} from 'react-bootstrap';
import CustomContexts from '../Components/CustomContexts/CustomContexts';
import CustomIcon from '../Components/CustomIcon';
import CustomTemplate from '../Components/CustomTemplate';
import CustomGroup from '../Components/CustomGroup';
import CustomChildren from '../Components/CustomChildren';
import CustomLink from '../Components/CustomLink';
import CustomMarkdown from '../Components/CustomMarkdown';
import CustomForm from '../Components/CustomForm';
import CustomInputSimple from '../Components/CustomInputSimple';
import CustomInputImage from '../Components/CustomInputImage';
import CustomInputBoolean from '../Components/CustomInputBoolean';
import CustomFormGroups from '../Components/CustomFormGroups';
import { CustomCropper } from '../Components/CustomInputImage/CustomCropper';
import CustomCollection from '../Components/CustomCollection';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const templateElements: Record<string, React.FC<any> | undefined> = {
  // Кастомные компоненты

  Contexts: CustomContexts,
  Icon: CustomIcon,
  Template: CustomTemplate,
  Group: CustomGroup,
  Children: CustomChildren,
  Link: CustomLink,
  Markdown: CustomMarkdown,
  FormContext: CustomForm,
  InputSimpleContext: CustomInputSimple,
  InputImageContext: CustomInputImage,
  InputBooleanContext: CustomInputBoolean,
  FormGroupsContext: CustomFormGroups,
  CustomCropper: CustomCropper,
  CollectionContext: CustomCollection,

  // React-bootstrap компоненты

  Accordion: Accordion,
  Alert: Alert,
  Badge: Badge,
  Button: Button,
  ButtonGroup: ButtonGroup,
  Card: Card,
  Carousel: Carousel,
  Col: Col,
  Collapse: Collapse,
  Dropdown: Dropdown,
  DropdownToggle: DropdownToggle,
  DropdownButton: DropdownButton,
  Figure: Figure,
  Form: Form,
  InputGroup: InputGroup,
  ListGroup: ListGroup,
  Modal: Modal,
  Navbar: Navbar,
  Nav: Nav,
  Offcanvas: Offcanvas,
  Overlay: Overlay,
  Placeholder: Placeholder,
  ProgressBar: ProgressBar,
  Row: Row,
  Spinner: Spinner,
  Table: Table,
  Tabs: Tabs,
  Tab: Tab,
  Toast: Toast,
  Breadcrumb: Breadcrumb,
  CloseButton: CloseButton,
  NavDropdown: NavDropdown,
  Pagination: Pagination,
};
