import _ from 'lodash';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { TStTemplateElem } from '../../../../../../../../../../Store/Slice/Site/types';

const CustomMarkdown: React.FC<{
  content: TStTemplateElem['children'];
  raw?: boolean;
}> = (props) => {
  if (!props.content) return null;
  if (!_.isString(props.content)) {
    console.log(
      'The value of the "children" attribute of the "Markdown" tag can only be a string!'
    );
    return null;
  }

  return (
    <ReactMarkdown rehypePlugins={props.raw ? [rehypeRaw] : undefined}>
      {_.toString(props.content)}
    </ReactMarkdown>
  );
};

export default CustomMarkdown;
