import _ from 'lodash';
import { RootState } from '../..';
import { denormalize } from 'normalizr';
import { user } from '../../NormSchema/user';
import { TSelectorArg } from '../../types';
import filterStoreItems from '../Helpers/filterStoreItems';
import { TUser } from './types';

export const getMe = (
  state: RootState
): (TUser & { token?: string }) | undefined => {
  const meID = state.sliceUI.meID;
  if (!meID) return;
  const meNr = _.get(state.sliceUsers.users, meID);
  const wgGroupsNr = state.sliceWorkGroups.wgGroups;
  const evEventsNr = state.sliceEvents.evEvents;
  const stAlerts = state.sliceSite.stAlerts;
  const jwt = state.sliceUI.jwt;
  const me = denormalize(meNr, user(1), {
    wgGroups: wgGroupsNr,
    evEvents: evEventsNr,
    stAlerts,
  });
  return {
    ...me,
    token: jwt,
  };
};

export const getUsers = (
  state: RootState,
  args?: TSelectorArg[]
): Record<string, TUser> | undefined => {
  const collection = state.sliceUsers.users;
  const filtered = args ? filterStoreItems(collection, args) : collection;
  if (!filtered) return;
  const wgGroupsNr = state.sliceWorkGroups.wgGroups;
  const evEventsNr = state.sliceEvents.evEvents;
  const stAlerts = state.sliceSite.stAlerts;
  const users = denormalize(Object.values(filtered), [user(1)], {
    wgGroups: wgGroupsNr,
    evEvents: evEventsNr,
    stAlerts,
  });
  return _.reduce(users, (r, v) => ({ ...r, [v.id]: v }), {});
};
