import { createSlice } from '@reduxjs/toolkit';
import {
  getInitialRequestSucceed,
  setInitialState,
} from '../../Saga/Initial/extraActions';
import { initialState } from './constants';
import { updateStoreData } from '../Helpers/updateStoreData';

export const sliceSite = createSlice({
  name: 'site',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(setInitialState, () => initialState);
    builder.addCase(getInitialRequestSucceed, (state, { payload }) => {
      updateStoreData(state, payload.site, { stPages: 'url', stIcons: 'name' });
    });
  },
  reducers: {},
});

export default sliceSite.reducer;
