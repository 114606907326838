import { schema } from 'normalizr';
import { user } from './user';
import { wgGroup } from './wgGroup';
import { cnDocument } from './cnDocument';

export const cnFolder = (depth: number): schema.Entity => {
  if (!depth) return new schema.Entity('cnFolders');
  return new schema.Entity('cnFolders', {
    ownerPerson: user(depth - 1),
    ownerGroup: wgGroup(depth - 1),
    memberFolders: [cnFolder(depth - 1)],
    memberDocuments: [cnDocument(depth - 1)],
  });
};
