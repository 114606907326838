import { LOCALE_DEFAULT } from '../../../Settings/api';
import { TSliceUI } from './types';

export const initialState: TSliceUI = {
  meID: undefined,
  jwt: undefined,
  locale: LOCALE_DEFAULT,
  navigateTo: undefined,
  navigateBack: undefined,
  customStates: {},
  attributesSave: {},
};
