import React from 'react';
import { TStTemplateElem } from '../../../../../../Store/Slice/Site/types';
import StaticElement from './Components/StaticElement';
import DynamicElement from './Components/DynamicElement';

const TemplateElement: React.FC<TStTemplateElem> = (templateElement) => {
  const element = templateElement.mutationSchema?.hasMutations ? (
    <DynamicElement {...templateElement} />
  ) : (
    <StaticElement {...templateElement} />
  );

  return <>{element}</>;
};

export default TemplateElement;
