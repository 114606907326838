/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import { TCustomCollection } from './types';
import { useInView } from 'react-intersection-observer';
import { useCollection } from './Hooks/useCollection';
import { useSelector } from 'react-redux';
import TemplateElements from '../../../../../..';
const CustomCollection: React.FC<TCustomCollection> = (props) => {
  return null;
  // const { name, pagination, contexts, childrenPrototype, lazyLoad } = props;
  // const [ref, inView] = useInView();
  // const normCollections = useSelector(selectCollections);
  // const contentItems = useSelector(selectContentItems);

  // const storedCollection = useMemo(
  //   () => getContentCollection(props.id, normCollections, contentItems),
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [contentItems, props.id, normCollections]
  // );

  // const collectionIsFull =
  //   !!storedCollection?.pagination?.page &&
  //   !!storedCollection.pagination.pageCount &&
  //   storedCollection.pagination.pageCount === storedCollection.pagination.page;

  // const load = pagination
  //   ? lazyLoad
  //     ? inView
  //     : pagination.page
  //     ? storedCollection?.pagination?.page
  //       ? pagination.page > storedCollection.pagination.page
  //       : true
  //     : !collectionIsFull
  //   : !collectionIsFull;

  // const collection = useCollection(load, props, storedCollection);

  // if (!name) {
  //   console.log('Required param "name" is not defined');
  //   return null;
  // }

  // const newContexts = {
  //   ...contexts,
  //   collection,
  // };

  // return (
  //   <>
  //     <TemplateElements elements={childrenPrototype} contexts={newContexts} />
  //     {pagination &&
  //     !collectionIsFull &&
  //     storedCollection?.pagination?.pageCount !== 0 &&
  //     lazyLoad ? (
  //       <div ref={ref} />
  //     ) : null}
  //   </>
  // );
};

export default CustomCollection;
