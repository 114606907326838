import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './Store';

const html = ReactDOM.createRoot(document.querySelector('html') as HTMLElement);
html.render(
  <Provider store={store}>
    <App />
  </Provider>
);

reportWebVitals();
