import { createSlice } from '@reduxjs/toolkit';
import {
  getInitialRequestPending,
  getInitialRequestRejected,
  getInitialRequestSucceed,
  setInitialState,
} from '../../Saga/Initial/extraActions';
import _ from 'lodash';
import { initialState } from './constants';
import { updateStoreData } from '../Helpers/updateStoreData';

export const sliceRequests = createSlice({
  name: 'requests',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(setInitialState, () => initialState);
    builder.addCase(getInitialRequestPending, (state) => {
      _.set(state.rqStates, 'init', { id: 'init', status: 'progress' });
    });
    builder.addCase(getInitialRequestRejected, (state) => {
      _.set(state.rqStates, 'init', { id: 'init', status: 'error' });
    });
    builder.addCase(getInitialRequestSucceed, (state, { payload }) => {
      updateStoreData(state, payload.requests);

      _.set(state.rqStates, 'init', { id: 'init', status: 'success' });
    });
  },
  reducers: {},
});

export default sliceRequests.reducer;
