import { LOCALE_DEFAULT } from '../../../Settings/api';
import { TSliceSite } from './types';

export const initialState: TSliceSite = {
  stLocales: {},
  stConfigs: {
    df0: {
      id: 'df0',
      locale: LOCALE_DEFAULT,
      headElements: [
        {
          tag: 'link',
          rel: 'apple-touch-icon',
          sizes: '152x152',
          href: '/assets/images/apple-touch-icon.png',
        },

        {
          tag: 'link',
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: '/assets/images/favicon-32x32.png',
        },

        {
          tag: 'link',
          rel: 'icon',
          type: 'image/png',
          sizes: '16x16',
          href: '/assets/images/favicon-16x16.png',
        },

        {
          tag: 'link',
          rel: 'mask-icon',
          href: '/assets/images/safari-pinned-tab.svg',
          color: '#5bbad5',
        },

        {
          tag: 'meta',
          name: 'msapplication-TileColor',
          content: '#da532c',
        },

        {
          tag: 'meta',
          name: 'theme-color',
          content: '#ffffff',
        },

        {
          tag: 'link',
          rel: 'manifest',
          href: '/site.webmanifest',
        },

        {
          tag: 'link',
          href: '/assets/css/bootstrap.min.css',
          rel: 'stylesheet',
        },
      ],
    },
  },
  stIcons: {
    df0: {
      id: 'df0',
      name: 'df-logo',
      width: 25,
      height: 25,
      path: 'M 19.149 2.501 L 19.149 12.506 C 19.151 13.985 18.661 15.424 17.758 16.593 C 16.856 17.763 15.591 18.597 14.163 18.966 C 12.735 19.334 11.226 19.216 9.873 18.628 C 8.52 18.041 7.4 17.017 6.691 15.721 L 6.691 20.616 C 8.178 21.689 9.932 22.33 11.758 22.468 C 13.585 22.606 15.414 22.235 17.044 21.397 C 18.675 20.558 20.043 19.285 20.999 17.716 C 21.955 16.148 22.46 14.345 22.461 12.506 L 22.461 5.8580000000000005 C 22.461 2.527 19.159 2.476 19.149 2.501 Z M 5.851 22.498 L 5.851 12.493 C 5.849 11.014 6.339 9.575 7.242 8.406 C 8.144 7.236 9.409 6.402 10.837 6.033 C 12.265 5.665 13.774000000000001 5.7829999999999995 15.127 6.371 C 16.48 6.958 17.6 7.982 18.309 9.278 L 18.309 4.383 C 16.822 3.31 15.068 2.669 13.242 2.531 C 11.415 2.393 9.586 2.7640000000000002 7.956 3.602 C 6.325 4.441 4.957 5.714 4.001 7.283 C 3.045 8.851 2.54 10.654 2.539 12.493 L 2.539 19.141 C 2.539 22.472 5.841 22.523 5.851 22.498 Z',
    },
  },
  stPages: {
    df0: {
      id: 'df0',
      url: '/loading',
      locale: LOCALE_DEFAULT,
      template: 'df2',
    },

    df1: {
      id: 'df2',
      url: '/error',
      locale: LOCALE_DEFAULT,
      template: 'df3',
    },

    df2: {
      id: 'df3',
      url: '/access',
      locale: LOCALE_DEFAULT,
      template: 'df4',
    },

    df3: {
      id: 'df4',
      url: '/manage/pages/:id',
      locale: LOCALE_DEFAULT,
      template: '',
    },
  },
  stTemplates: {
    df0: {
      id: 'df0',
      locale: LOCALE_DEFAULT,
      name: 'df-base',
      template: {
        tag: 'div',
        style: { minHeight: '100vh' },
        className: 'container-lg vstack gap-3',
        children: {
          tag: 'Children',
        },
      },
    },

    df1: {
      id: 'df1',
      locale: LOCALE_DEFAULT,
      name: 'df-spinner',
      template: {
        tag: 'div',
        className:
          'text-<%= current.template.variables?.color || "secondary" %> opacity-25',
        mutationSchema: {
          selectors: [],
          hasMutations: true,
        },
        children: [
          {
            tag: 'style',
            children:
              '@keyframes rotate { 0% { transform: rotate(0deg); } 50% { transform: rotate(180deg); } 100% { transform: rotate(360deg); } } .df-spinner svg path { shape-rendering: auto; } .df-spinner { display: flex; animation: rotate 1s linear infinite; }',
          },
          {
            tag: 'div',
            className: 'df-spinner display-4',
            children: [
              {
                tag: 'Icon',
                icon: 'df-logo',
              },
            ],
          },
        ],
      },
    },

    df2: {
      id: 'df2',
      locale: LOCALE_DEFAULT,
      name: 'df-page-loading',
      template: {
        tag: 'Template',
        name: 'df-base',
        children: {
          tag: 'div',
          className: 'align-items-center d-flex flex-column mx-auto my-auto',
          children: [
            {
              tag: 'Template',
              name: 'df-spinner',
            },
            {
              tag: 'p',
              className: 'mt-3',
              children: ' Загрузка...',
            },
          ],
        },
      },
    },

    df3: {
      id: 'df3',
      locale: LOCALE_DEFAULT,
      name: 'df-page-error',
      template: {
        tag: 'Template',
        name: 'df-base',
        children: {
          tag: 'div',
          className: 'align-items-center d-flex flex-column mx-auto my-auto',
          children: {
            tag: 'Group',
            variables: {
              ru: {
                500: [
                  'Внутренняя ошибка сервера.',
                  { tag: 'br' },
                  {
                    tag: 'span',
                    children:
                      'Мы уже работаем над проблемой. Скоро все будет в порядке!',
                    className: 'small mt-2',
                  },
                ],
                404: [
                  'Страница, которую вы запрашиваете - не найдена.',
                  { tag: 'br' },
                  {
                    tag: 'span',
                    children: [
                      'Попробуйте начать с ',
                      {
                        tag: 'Link',
                        to: '/',
                        children: 'главной страницы.',
                      },
                    ],
                    className: 'small mt-2',
                  },
                ],
                403: [
                  'У вас не достаточно прав для доступа к странице.',
                  { tag: 'br' },
                  {
                    tag: 'span',
                    children: [
                      'Попробуйте ',
                      {
                        tag: 'Link',
                        to: '/access',
                        children: 'войти в систему',
                      },
                      ' или обратитесь к администратору.',
                    ],
                    className: 'small mt-2',
                  },
                ],
              },
            },
            children: [
              {
                tag: 'p',
                className: 'fs-4',
                children: 'Ошибка',
              },
              {
                tag: 'p',
                className: 'display-1',
                children: '<%= current.page?.error?.code || "500" %>',
                mutationSchema: {
                  hasMutations: true,
                  selectors: [],
                },
              },
              {
                tag: 'p',
                className: 'mb-0 text-center',
                children:
                  '<% return current.template?.variables?.[current.locale]?.[current.page.error?.code || 500] %>',
                mutationSchema: {
                  hasMutations: true,
                  selectors: [],
                },
              },
            ],
          },
        },
      },
    },

    df4: {
      id: 'df4',
      locale: LOCALE_DEFAULT,
      name: 'df-page-access',
      template: {
        tag: 'Template',
        name: 'df-base',
        children: {
          tag: 'div',
          className:
            'mx-auto my-auto col-12 col-sm-10 col-md-8 col-lg-6 d-flex flex-column gap-3 border p-3',
          children: [
            {
              tag: 'p',
              className: 'mb-0',
              children: [
                'Забыли пароль?',
                { tag: 'br' },
                'Воспользуйтесь процедурой ',
                {
                  tag: 'Link',
                  to: '?layout=reset',
                  className: 'fw-bold',
                  children: 'восстановления пароля.',
                },
              ],
            },
          ],
        },
      },
    },
  },
  stAlerts: {},
  stFunctions: {},
  stBlocks: {},
};
