import { useEffect } from 'react';
import { execMultiplyActions } from '../../Library/Action/Helpers/execMultiplyActions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { TSitePageContext } from './types';
import { Mutator } from '../../Library/Mutator/Mutator';
import TemplateElements from './Components/TemplateElements';
import { TStAlert, TStPage } from '../../Store/Slice/Site/types';
import { getPageAlerts, getSiteConfig } from '../../Store/Slice/Site/selectors';
import { RootState } from '../../Store';
import { useMutatorSelectors } from '../../Library/Mutator/Hooks/useMutatorSelectors';
import { TAbstractStoreObject } from '../../Store/types';
import { setNavigateBack } from '../../Store/Slice/UI';
import { TEMPLATE_STATIC } from './constants';

/**
 * Шаблон страниц.
 - На странице происходит редирект в случае, если он есть в sliceUI -> redirectTo.
 - При загрузке страницы запускаются action`ы.
 - При помощи функции {@link constructAlertBlocks} собираются блоки с уведомлениями.
 - Блоки со значением атрибута fetchStatus отличным от success, догружаются отдельно (повторяется при изменении объекта page).
 * @returns Компонент страницы
 */
const Page: React.FC<TStPage> = (page) => {
  const dispatch = useDispatch();
  const stConfig = useSelector(getSiteConfig);

  const pageAlerts = useSelector((state: RootState) =>
    getPageAlerts(state, page.id)
  );

  const pageContext: TSitePageContext = {
    id: page.id,
    url: page.url,
    error: page.error,
  };

  const mutationContext = useMutatorSelectors({ page: pageContext });

  const mutator = new Mutator(mutationContext);
  const mutantAlerts = pageAlerts
    .map((alert) => mutator.mutate(alert as TAbstractStoreObject) as TStAlert)
    .filter(
      (item) => !item.showPositions?.find((position) => position.excluded)
    );

  const navigateTo = useSelector(
    (state: RootState) => state.sliceUI.navigateTo
  );
  const navigate = useNavigate();
  useEffect(() => {
    if (navigateTo) {
      navigate(navigateTo.url);
      if (navigateTo.action) {
        execMultiplyActions(
          _.isArray(navigateTo.action)
            ? navigateTo.action
            : [navigateTo.action],
          mutator
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigateTo]);

  const navigateBack = useSelector(
    (state: RootState) => state.sliceUI.navigateBack
  );

  useEffect(() => {
    if (navigateBack) {
      navigate(-1);
      dispatch(setNavigateBack());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigateBack]);

  const contexts = {
    page: {
      ...pageContext,
      alerts: mutantAlerts,
    },
  };

  useEffect(() => {
    if (page.onLoad) {
      mutator.addContext({ current: contexts });
      const actions = _.isArray(page.onLoad) ? page.onLoad : [page.onLoad];
      const mutantActions = actions.map((action) =>
        mutator.applyObjectVariables(action)
      );
      execMultiplyActions(mutantActions.filter((action) => !action.excluded));
    }
  }, [page.onLoad]);

  const headElements = [
    ...TEMPLATE_STATIC.HEAD_ELEMENTS,
    ...(stConfig?.headElements
      ? _.isArray(stConfig.headElements)
        ? stConfig.headElements
        : [stConfig.headElements]
      : []),
  ];

  const bodyElements = [
    ...TEMPLATE_STATIC.BODY_ELEMENTS,
    ...(page.template?.template
      ? _.isArray(page.template.template)
        ? page.template.template
        : [page.template.template]
      : []),
  ];

  return (
    <>
      <head>
        <TemplateElements elements={headElements} contexts={contexts} />
      </head>
      <body>
        <TemplateElements elements={bodyElements} contexts={contexts} />
      </body>
    </>
  );
};

export default Page;
