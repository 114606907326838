import TemplateElement from '../../../..';
import { TChildren } from './types';

const CustomChildren: React.FC<TChildren> = ({ group, contexts }) => {
  const children = contexts?.template?.children?.filter(
    (item) => item.group === group
  );
  if (!children || !children.length) return null;
  return (
    <>
      {children.map((templateElement, i) => (
        <TemplateElement key={i} {...{ ...templateElement, contexts }} />
      ))}
    </>
  );
};

export default CustomChildren;
