import _ from 'lodash';
import { TMutationContext, TMutatorSelectors } from '../types';
import { constructUrlParams } from './constructUrlParams';
import { API_URL_PREFIX } from '../../../Settings/api';
import QueryString from 'qs';
import Action from '../../Action';
import { TTemplateElemContext } from '../../../Components/Page/Components/TemplateElements/Components/TemplateElement/types';
import store from '../../../Store';
import { getMe, getUsers } from '../../../Store/Slice/Users/selectors';
import { getGlobalVariables } from '../../../Store/Slice/Site/selectors';
import { getWgGroups } from '../../../Store/Slice/WorkGroups/selectors';
import {
  getCnDocuments,
  getCnFolders,
} from '../../../Store/Slice/Content/selectors';
import { getEvEvents } from '../../../Store/Slice/Events/selectors';

export const constructMutatorContexts = (
  current?: TTemplateElemContext,
  selectors?: TMutatorSelectors
): TMutationContext => {
  const rootState = store.getState();
  const location = selectors?.location || window.location;
  const query = QueryString.parse(location.search.replace(/^\?/, ''));

  const locale = selectors?.locale || rootState.sliceUI.locale;

  const globalVariables = selectors?.variables || getGlobalVariables(rootState);

  return {
    current: {
      locale,
      url: {
        pathname: location.pathname,
        params:
          selectors?.params ||
          constructUrlParams(
            location.pathname,
            _.map(rootState.sliceSite.stPages, (page) => page.url)
          ) ||
          {},
        query: query || {},
        prefix: API_URL_PREFIX,
      },
      user: selectors?.myUser || getMe(rootState),
      ...current,
    },

    global: {
      states: selectors?.states || rootState.sliceUI.customStates,
      variables: globalVariables,
      collections: {
        users: selectors?.users || getUsers(rootState) || {},
        workGroups: selectors?.wgGroups || getWgGroups(rootState) || {},
        documents: selectors?.cnDocuments || getCnDocuments(rootState) || {},
        folders: selectors?.cnFolders || getCnFolders(rootState) || {},
        events: selectors?.evEvents || getEvEvents(rootState) || {},
      },
    },

    action: Action,
  };
};
