import { all } from 'redux-saga/effects';
import initial from './Initial';

/**
 * Корневая сага
 */
function* sagaRoot() {
  yield all([initial()]);
}

export default sagaRoot;
