import _ from 'lodash';
import { TSiteActionProps } from '../../../Store/types';
import store from '../../../Store';
import { setJWT } from '../../../Store/Slice/UI';
const SetToken = (arg: TSiteActionProps['argument']) => {
  if (!arg || !_.isString(arg)) {
    console.log('argument must be defined and be a string!');
    return;
  }
  store.dispatch(setJWT(arg));
};

export default SetToken;
