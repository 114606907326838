import { TTagMutations } from '../../types';

export const CONTEXT_UPDATERS: string[] = [
  'Template',
  'Group',
  'FormContext',
  'InputSimpleContext',
  'InputImageContext',
  'InputBooleanContext',
  'FormGroupsContext',
  'ToastsContext',
  'CollectionContext',
];

export const VOID_ELEMENTS: string[] = [
  'area',
  'base',
  'br',
  'col',
  'embed',
  'hr',
  'img',
  'input',
  'link',
  'meta',
  'param',
  'source',
  'track',
  'wbr',

  'Card.Img',
  'FormControl',
  'Form.Control',
  'Form.Check',
  'Form.Range',
  'Form.Textarea',
  'InputGroup.Checkbox',
  'Icon',
  'Template',
];

export const DOM_CREATOR_EXCLUDE_ATTRIBUTES: Record<string, string[]> = {
  CUSTOM: [
    'tag',
    'mutationSchema',
    'excluded',
    'childrenPrototype',
    'label',
    'isInvalid',
    'isValid',
  ],
  DEFAULT: [
    'children',
    'tag',
    'excluded',
    'group',
    'query',
    'mutationSchema',
    'childrenPrototype',
    'label',
    'contexts',
  ],
};

export const TAG_MUTATION: TTagMutations = {
  EXCLUDE: {
    VALUES: {
      all: [],
    },
    ATTRIBUTES: {
      all: [
        /mutationProps/,
        /children/,
        /onSucceed/,
        /onRejected/,
        /mutationSchema/,
        /validationSchema/,
      ],
      FormContext: [/attributes/],
    },
  },
  INCLUDE: {
    VALUES: {
      all: [],
    },
    ATTRIBUTES: {
      all: [],
    },
  },
};
