import { createSlice } from '@reduxjs/toolkit';
import { TSliceEvents } from './types';
import { setInitialState } from '../../Saga/Initial/extraActions';

export const initialState: TSliceEvents = {
  evEvents: {},
};

export const sliceEvents = createSlice({
  name: 'events',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(setInitialState, () => initialState);
  },
  reducers: {},
});

export default sliceEvents.reducer;
