import _ from 'lodash';
import { TAbstractStore } from '../../types';

export const updateStoreData = (
  store: TAbstractStore,
  payloadStore: TAbstractStore,
  replaceAttributeNames?: Record<string, string>
) => {
  for (const collectionName of Object.keys(payloadStore)) {
    const storeCollection = _.get(store, collectionName);
    const payloadCollection = _.get(payloadStore, collectionName);
    const replaceAttributeName = replaceAttributeNames?.[collectionName];
    if (!replaceAttributeName) {
      _.set(store, collectionName, _.merge(storeCollection, payloadCollection));
    } else {
      const replaceValues = _.compact(
        Object.values(payloadCollection).map(
          (item) => item?.[replaceAttributeName]
        )
      );
      const filteredStoreCollection = _.reduce(
        storeCollection,
        (r, v, k) => {
          if (!v) return { ...r };
          const replaceValue = v[replaceAttributeName];
          if (
            !replaceValue ||
            replaceValues.find((value) => _.isEqual(value, replaceValue))
          )
            return { ...r };
          return { ...r, [k]: v };
        },
        {} as TAbstractStore['']
      );

      _.set(
        store,
        collectionName,
        _.merge(filteredStoreCollection, payloadCollection)
      );
    }
  }
};
