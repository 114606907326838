import { call, put } from 'redux-saga/effects';
import { TAbstractStore } from '../../types';
import { getLsDataRequestSucceed } from '../Initial/extraActions';

function* getLSData() {
  const lsDataObj: Partial<TAbstractStore> | undefined = yield call(() => {
    const lsData = localStorage.getItem('UIAttributes');
    if (!lsData) return null;
    return JSON.parse(lsData);
  });
  yield put(getLsDataRequestSucceed(lsDataObj));
}

export default getLSData;
