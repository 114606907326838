/* eslint-disable @typescript-eslint/no-unused-vars */
import { Icon, IconProps } from '@iconify/react';
import { TCustomIcon } from './types';
import { RootState } from '../../../../../../../../../../Store';
import { useSelector } from 'react-redux';
import { getIcon } from '../../../../../../../../../../Store/Slice/Site/selectors';
import { defaultIcon } from './Helpers/defaultIcon';

/**
 * Компонент иконки
 *
 * @param props - Свойства компонента
 * @returns иконка
 */
const CustomIcon: React.FC<TCustomIcon> = (props) => {
  const icon =
    useSelector((state: RootState) => getIcon(state, props.icon)) ||
    defaultIcon;

  const iconProps: IconProps = {
    icon: {
      body: `<path d="${icon.path}" fill="currentcolor" />`,
      width: icon.width,
      height: icon.height,
    },
    inline: !!props.inline,
    rotate: props.rotate,
    className: props.className,
  };
  return <Icon {...iconProps} />;
};

export default CustomIcon;
