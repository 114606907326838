import _ from 'lodash';
import { TStTemplateElem } from '../../../../Slice/Site/types';
import ContextSelectors from '../ContextSelectors';
import {
  TAbstractStore,
  TBaseDataObject,
  TFetchResponse,
} from '../../../../types';
import { RESPONSE } from './constants';

const constructPayload = (respData: TFetchResponse['data']) => {
  const selectors = new ContextSelectors();
  const applySelectors = (elem: TStTemplateElem | TStTemplateElem[]) => {
    return _.isArray(elem)
      ? elem.map((item) => selectors.element(item))
      : selectors.element(elem);
  };

  const updateCollectionItem = (
    collectionItem: TBaseDataObject,
    tmpAttrNames: string[]
  ) => {
    const tmpAttrs = _.reduce(
      tmpAttrNames,
      (r, v) => {
        const tmpAttr = collectionItem[v] as
          | TStTemplateElem
          | TStTemplateElem[]
          | undefined;
        if (!tmpAttr) return { ...r };
        return {
          ...r,
          [v]: applySelectors(tmpAttr),
        };
      },
      {}
    );

    return {
      ...collectionItem,
      ...tmpAttrs,
    };
  };

  const updateStoreCollection = (
    collection: Record<string, TBaseDataObject | undefined>,
    tmpAttrNames: string[]
  ) =>
    _.reduce(
      collection,
      (r, v, k) => {
        if (!v) return { ...r };
        return {
          ...r,
          [k]: updateCollectionItem(v, tmpAttrNames),
        };
      },
      {}
    );

  const updateSlice = (
    slice: TAbstractStore,
    sliceTmpAttrNames: Record<string, string[] | undefined>
  ) =>
    _.reduce(
      slice,
      (r, v, k) => {
        const tmpAttrNames = sliceTmpAttrNames[k];
        if (!tmpAttrNames) return { ...r, [k]: v };
        return {
          ...r,
          [k]: updateStoreCollection(v, tmpAttrNames),
        };
      },
      {}
    );

  return _.reduce(
    respData,
    (r, v, k) => {
      const sliceTmpAttrNames = RESPONSE.TEMPLATE_ELEMENTS[k];
      if (!sliceTmpAttrNames) return { ...r, [k]: v };
      return {
        ...r,
        [k]: updateSlice(v, sliceTmpAttrNames),
      };
    },
    {} as Record<string, TAbstractStore>
  );
};

export default constructPayload;
