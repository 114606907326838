import { createAction } from '@reduxjs/toolkit';
import { TAbstractStore, TFetchResponseError } from '../../types';
import { TSliceUI } from '../../Slice/UI/types';

export const APP_INITIAL_STATE = 'app/initial';

export const setInitialState = createAction(APP_INITIAL_STATE);

export const GET_LS_DATA_REQUEST = 'get-ls-data/request';
export const GET_LS_DATA_REQUEST_SUCCEED = 'get-ls-data/request-succeed';

export const getLsDataRequest =
  createAction<TAbstractStore>(GET_LS_DATA_REQUEST);
export const getLsDataRequestSucceed = createAction<
  Partial<TSliceUI> | undefined
>(GET_LS_DATA_REQUEST_SUCCEED);

export const GET_INITIAL_REQUEST = 'get-initial/request';
export const GET_INITIAL_REQUEST_PENDING = 'get-initial/request-pending';
export const GET_INITIAL_REQUEST_REJECTED = 'get-initial/request-rejected';
export const GET_INITIAL_REQUEST_SUCCEED = 'get-initial/request-succeed';

export const getInitialRequest = createAction(GET_INITIAL_REQUEST);
export const getInitialRequestPending = createAction(
  GET_INITIAL_REQUEST_PENDING
);
export const getInitialRequestRejected = createAction<TFetchResponseError>(
  GET_INITIAL_REQUEST_REJECTED
);
export const getInitialRequestSucceed = createAction<
  Record<string, TAbstractStore>
>(GET_INITIAL_REQUEST_SUCCEED);
