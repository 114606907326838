import { ChangeEventHandler } from 'react';
import { TCustomInputBoolean, TCustomInputBooleanContext } from './types';
import { TFormInputSimple } from '../CustomForm/types';
import TemplateElements from '../../../../../..';

const CustomInputBoolean: React.FC<TCustomInputBoolean> = ({
  childrenPrototype,
  contexts,
  name,
}) => {
  const form = contexts?.form;
  const formInput = form?.inputs[name || 'unknown-boolean-input'];

  if (!form) {
    console.log(
      'Tag <InputSimpleContext> must be child of the <FormContext> tag!'
    );
    return null;
  }

  if (!name) {
    console.log('Check required attributes: {name: string}');
    return null;
  }

  const allowedTypes = ['checkbox', 'radio'];

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const inputType = e.target.type;
    const inputChecked = e.target.checked;
    if (!allowedTypes.includes(inputType)) {
      console.log(`Type ${inputType} is not supported!`);
      return;
    }

    form.handleChange({
      ...formInput,
      name,
      value: inputChecked,
    } as TFormInputSimple);
  };

  const input: TCustomInputBooleanContext = {
    name: name || 'unknown-boolean-input',
    checked: !!formInput?.value,
    errors: formInput?.errors || [],
    handleChange,
  };

  const newContexts = {
    ...contexts,
    input,
  };

  return (
    <TemplateElements elements={childrenPrototype} contexts={newContexts} />
  );
};

export default CustomInputBoolean;
