import _ from 'lodash';
import { TSiteActionProps } from '../../../Store/types';

const Throw = (arg?: TSiteActionProps['argument']) => {
  setTimeout(() => {
    throw new Error(
      arg && (_.isString(arg) || _.isInteger(arg)) ? arg.toString() : '500'
    );
  }, 300);
};

export default Throw;
