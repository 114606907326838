import _ from 'lodash';
import { TSliceUI } from '../UI/types';

export const updateLSData = (
  slice: Pick<
    TSliceUI,
    'locale' | 'meID' | 'jwt' | 'customStates' | 'attributesSave'
  >
) => {
  if (!slice.attributesSave.ls?.length) return;
  const lsAttributes = _.pick(slice.customStates, slice.attributesSave.ls);
  const lsData = {
    locale: slice.locale,
    jwt: slice.jwt,
    customStates: lsAttributes,
  };
  localStorage.setItem('UIAttributes', JSON.stringify(lsData));
};
