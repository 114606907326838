export const RESPONSE: {
  COLLECTIONS: Record<string, string[] | undefined>;
  TEMPLATE_ELEMENTS: Record<
    string,
    Record<string, string[] | undefined> | undefined
  >;
} = {
  COLLECTIONS: {
    content: ['cnFolders', 'cnDocuments'],
    events: ['evEvents'],
    requests: ['rqSets'],
    site: [
      'stLocales',
      'stConfigs',
      'stIcons',
      'stPages',
      'stTemplates',
      'stAlerts',
      'stFunctions',
      'stBlocks',
    ],
    users: ['users'],
    workGroups: ['wgGroups'],
  },
  TEMPLATE_ELEMENTS: {
    site: {
      stConfigs: ['headElements'],
      stPages: ['headElements'],
      stTemplates: ['template'],
      stAlerts: ['stAlerts'],
      stBlocks: ['template'],
    },
  },
};
