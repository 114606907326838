import _ from 'lodash';
import { TSiteActionProps } from '../../../Store/types';

/**
 * Перенаправить на указаный в props.argument адрес (присвоится window.location.href)
 */
const RedirectTo = (arg?: TSiteActionProps['argument']) => {
  if (arg && _.isString(arg)) {
    window.location.href = arg;
  } else {
    console.log('wrong argument for redirectTo action!');
  }
};

export default RedirectTo;
