import { schema } from 'normalizr';
import { user } from './user';
import { wgGroup } from './wgGroup';

export const evEvent = (depth?: number): schema.Entity => {
  if (!depth) return new schema.Entity('evEvents');
  return new schema.Entity('evEvents', {
    ownerPerson: user(depth - 1),
    ownerGroup: wgGroup(depth - 1),
    memberGroups: {
      others: [wgGroup(depth - 1)],
      self: [wgGroup(depth - 1)],
      both: [wgGroup(depth - 1)],
    },
    memberPersons: {
      others: [user(depth - 1)],
      self: [user(depth - 1)],
      both: [user(depth - 1)],
    },
  });
};
