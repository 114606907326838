import QueryString from 'qs';
import {
  TBaseDataObject,
  TFetchResponse,
  TRequestMethod,
} from '../../../types';
import store from '../../..';

export type TRequestProps = {
  url: string;
  method?: TRequestMethod;
  headers?: { [a: string]: string };
  query?: Partial<TBaseDataObject>;
  body?: TBaseDataObject;
};

export const fetchAPI = async ({
  url,
  method,
  headers,
  query,
  body,
}: TRequestProps): Promise<Partial<TFetchResponse>> => {
  const jwt = store.getState().sliceUI.jwt;
  const queryString = QueryString.stringify(query);

  const errorResponse = {
    data: {},
    meta: {
      pagination: {
        page: 1,
        pageSize: 1,
      },
    },
    error: {
      status: '500',
      name: 'Server error',
      message: 'Server internal error',
      details: {},
    },
  };

  try {
    const response = await fetch(
      `${url}${queryString ? '?' + queryString : ''}`,
      {
        method: method || 'GET',
        headers: {
          ...headers,
          ...(jwt ? { Authorization: `Bearer ${jwt}` } : {}),
        },
        body: JSON.stringify(body),
      }
    );
    try {
      const responseObj = await response.json();
      if (!responseObj.data)
        return {
          ...errorResponse,
          error: {
            ...errorResponse.error,
            message: 'Wrong server response format',
          },
        } as Partial<TFetchResponse>;
      return responseObj as Partial<TFetchResponse>;
    } catch {
      return {
        ...errorResponse,
        error: {
          ...errorResponse.error,
          message: 'Wrong server response format',
        },
      } as Partial<TFetchResponse>;
    }
  } catch (err) {
    return errorResponse as Partial<TFetchResponse>;
  }
};
