import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import sagaRoot from './Saga';
import sliceSite from './Slice/Site';
import sliceRequests from './Slice/Requests';
import sliceUI from './Slice/UI';
import sliceContent from './Slice/Content';
import sliceEvents from './Slice/Events';
import sliceUsers from './Slice/Users';
import sliceWorkGroups from './Slice/WorkGroups';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    sliceContent,
    sliceEvents,
    sliceRequests,
    sliceSite,
    sliceUI,
    sliceUsers,
    sliceWorkGroups,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: ['payload.action'],
      },
    }).concat(sagaMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
});

sagaMiddleware.run(sagaRoot);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export default store;
