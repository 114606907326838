import React, { ChangeEventHandler, useState } from 'react';
import { TCustomInputSimple, TCustomInputSimpleContext } from './types';
import { maskValue } from './Helpers/maskValue';
import _ from 'lodash';
import { TFormInputContext } from '../CustomForm/types';
import TemplateElements from '../../../../../..';
import { TTemplateElemContext } from '../../../../types';

const CustomInputSimple: React.FC<TCustomInputSimple> = ({
  name,
  contexts,
  childrenPrototype,
  timeout,
}) => {
  const form = contexts?.form;
  const formInput = _.get(
    contexts,
    `form.inputs.${name || 'unknown-simple-input'}`,
    undefined
  );
  const initialValue = _.get(formInput, 'value', undefined);
  const mask = _.get(formInput, 'inputMask', undefined);
  const [value, setValue] = useState<
    TCustomInputSimpleContext['value'] | undefined
  >(
    _.isString(initialValue) || _.isNumber(initialValue)
      ? initialValue
      : undefined
  );
  const [timer, setTimer] = useState<NodeJS.Timeout>();

  if (!form || !formInput) {
    console.log(
      'Tag <InputSimpleContext> must be child of the <FormContext> tag!'
    );
    return null;
  }

  if (!name) {
    console.log('Check required attributes: {name: string}');
    return null;
  }

  const allowedTypes = [
    'button',
    'text',
    'color',
    'date',
    'datetime-local',
    'email',
    'number',
    'password',
    'search',
    'tel',
    'time',
    'url',
    'week',
    'month',
    'select-one',
    'select-multiple',
    'checkbox',
    'radio',
    'textarea',
  ];

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    const inputType = e.target.type;
    const inputValue = e.target.value;
    if (!allowedTypes.includes(inputType)) {
      console.log(`Type ${inputType} is not supported!`);
      return;
    }

    const newValue =
      ['text', 'search', 'email', 'password', 'tel', 'url'].includes(
        inputType
      ) && mask
        ? maskValue(
            inputValue,
            new RegExp(
              _.isArray(mask) ? mask[0] : mask,
              _.isArray(mask) ? mask[1] : undefined
            )
          )
        : inputValue;

    setValue(newValue);
    if (timeout) {
      clearTimeout(timer);
      setTimer(
        setTimeout(() => {
          form.handleChange({
            ...formInput,
            name,
            value: newValue,
          } as TFormInputContext);
        }, 500)
      );
    } else {
      form.handleChange({
        ...formInput,
        name,
        value: newValue,
      } as TFormInputContext);
    }
  };

  const newContexts: TTemplateElemContext = {
    ...contexts,
    input: {
      name: name || 'unknown-simple-input',
      value,
      handleChange,
      errors: formInput.errors,
      required: formInput.required,
    },
  };

  return (
    <TemplateElements elements={childrenPrototype} contexts={newContexts} />
  );
};

export default CustomInputSimple;
