import { denormalize } from 'normalizr';
import { RootState } from '../..';
import { TSelectorArg } from '../../types';
import filterStoreItems from '../Helpers/filterStoreItems';
import { wgGroup } from '../../NormSchema/wgGroup';
import _ from 'lodash';

export const getWgGroups = (state: RootState, args?: TSelectorArg[]) => {
  const collection = state.sliceWorkGroups.wgGroups;
  const filtered = args ? filterStoreItems(collection, args) : collection;
  if (!filtered) return;
  const evEventsNr = state.sliceEvents.evEvents;
  const usersNr = state.sliceUsers.users;
  const wgGroups = denormalize(Object.values(filtered), [wgGroup(1)], {
    wgGroups: collection,
    evEvents: evEventsNr,
    users: usersNr,
  });
  return _.reduce(wgGroups, (r, v) => ({ ...r, [v.id]: v }), {});
};
