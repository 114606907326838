import _ from 'lodash';
import { RootState } from '../..';
import { stConfig } from '../../NormSchema/stConfig';
import { denormalize } from 'normalizr';
import { TStAlert, TStConfig, TStPage } from './types';
import { stPage } from '../../NormSchema/stPage';

export const getSiteConfig = (state: RootState): TStConfig | undefined => {
  const locale = state.sliceUI.locale;
  const stConfigsNr = state.sliceSite.stConfigs;
  const stConfigNr = _.find(stConfigsNr, { locale });
  if (!stConfigNr) return;
  const stAlerts = state.sliceSite.stAlerts;
  return denormalize(stConfigNr, stConfig(1), { stAlerts });
};

export const getGlobalVariables = (state: RootState) => {
  const locale = state.sliceUI.locale;
  const stConfigsNr = state.sliceSite.stConfigs;
  return _.find(stConfigsNr, { locale })?.globalVariables;
};

export const getSitePages = (state: RootState): TStPage[] => {
  const locale = state.sliceUI.locale;
  const stPagesNr = _.filter(state.sliceSite.stPages, { locale });
  const stTemplates = state.sliceSite.stTemplates;
  return stPagesNr.map((page) => denormalize(page, stPage(1), { stTemplates }));
};

export const getPageAlerts = (state: RootState, pageID: string): TStAlert[] => {
  const locale = state.sliceUI.locale;
  return Object.values(state.sliceSite.stAlerts).filter(
    (alert) =>
      _.find(alert.showPositions || [], { page: pageID }) &&
      alert.locale === locale
  );
};

export const getTemplate = (state: RootState, name: string) => {
  const templates = state.sliceSite.stTemplates;
  return _.find(templates, (template) => template.name === name);
};

export const getIcon = (state: RootState, name: string) => {
  const templates = state.sliceSite.stIcons;
  return _.find(templates, (icon) => icon.name === name);
};
