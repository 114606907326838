import { schema } from 'normalizr';
import { user } from './user';
import { evEvent } from './evEvent';

export const wgGroup = (depth?: number): schema.Entity => {
  if (!depth) return new schema.Entity('wgGroups');
  return new schema.Entity('wgGroups', {
    ownerPerson: user(depth - 1),
    ownerGroup: wgGroup(depth - 1),
    membershipGroups: {
      others: [wgGroup(depth - 1)],
      self: [wgGroup(depth - 1)],
      both: [wgGroup(depth - 1)],
    },
    membershipEvents: {
      others: [evEvent(depth - 1)],
      self: [evEvent(depth - 1)],
      both: [evEvent(depth - 1)],
    },
    memberGroups: {
      others: [wgGroup(depth - 1)],
      self: [wgGroup(depth - 1)],
      both: [wgGroup(depth - 1)],
    },
    memberPersons: {
      others: [user(depth - 1)],
      self: [user(depth - 1)],
      both: [user(depth - 1)],
    },
  });
};
