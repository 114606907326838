import { RootState } from '../..';
import { TSelectorArg } from '../../types';
import filterStoreItems from '../Helpers/filterStoreItems';

export const getRqStatuses = (state: RootState, args?: TSelectorArg[]) => {
  const collection = state.sliceRequests.rqStates;
  if (!args?.length) return collection;
  return filterStoreItems(collection, args);
};

export const getRqSets = (state: RootState, args?: TSelectorArg[]) => {
  const collection = state.sliceRequests.rqSets;
  if (!args?.length) return collection;
  return filterStoreItems(collection, args);
};
