export const SITE = {
  SELECTORS: {
    location: [
      /current\??\.url\??\.params/gm,
      /current\??\.url\??\.query/gm,
      /current\??\.url\??\.pathname/gm,
    ],
    params: [/current\??\.url\??\.params/gm],
    locale: [/current\??\.locale/gm],
    states: [/global\??\.states/gm],
    myUser: [/current\??\.user/gm],
    variables: [/global\??\.variables/gm],
    users: [/global\??\.collections\??\.users/gm],
    wgGroups: [/global\??\.collections\??\.workGroups/gm],
    cnDocuments: [/global\??\.collections\??\.documents/gm],
    cnFolders: [/global\??\.collections\??\.folders/gm],
    evEvents: [/global\??\.collections\??\.events/gm],
  },
};
