import _ from 'lodash';
import { templateElements } from '../DynamicElement/helpers/templateElements';
import { applyHandlers } from './helpers/applyHandlers';
import { TStTemplateElem } from '../../../../../../../../Store/Slice/Site/types';
import { TMutationContext } from '../../../../../../../../Library/Mutator/types';
import CustomElement from '../DynamicElement/Components/CustomElement';
import DefaultElement from '../DynamicElement/Components/DefaultElement';

const StaticElement: React.FC<TStTemplateElem> = (templateElement) => {
  if (templateElement.excluded) return null;
  const withHandlers = Object.keys(templateElement).find((key) =>
    /^on[A-Z].+/.test(key)
  )
    ? (applyHandlers(templateElement) as TStTemplateElem)
    : templateElement;

  const customElement = _.get(templateElements, withHandlers.tag || 'unknown');

  const domElement = customElement ? (
    <CustomElement
      {...{
        component: customElement,
        templateElement: withHandlers as TStTemplateElem & {
          contexts: Partial<TMutationContext>;
        },
      }}
    />
  ) : (
    <DefaultElement {...withHandlers} />
  );

  return <>{domElement}</>;
};

export default StaticElement;
