import _ from 'lodash';
import { TSelectorArg, TStoreCollections } from '../../types';

function filterStoreItems<B extends TStoreCollections[keyof TStoreCollections]>(
  collection: B,
  args: TSelectorArg[]
): B | undefined {
  const filtered = _.reduce(
    collection,
    (r, v, k) => {
      const isValid = args
        ? !args
            .map((arg) => _.get(v, arg.name) === arg.value)
            ?.find((item) => item === false)
        : false;
      if (!isValid) return { ...r };
      return { ...r, [k]: v };
    },
    {} as B
  );
  if (!Object.values(filtered).length) return;
  return filtered;
}

export default filterStoreItems;
