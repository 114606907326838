import _ from 'lodash';
import { TCustomTemplate } from './types';
import { updateElemVariables } from './helpers/updateElemVariables';
import TemplateElements from '../../../../../..';
import { TTemplateElemContext } from '../../../../types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../../../../Store';
import { getTemplate } from '../../../../../../../../../../Store/Slice/Site/selectors';

/**
 * Компонент макета с дочерними элементами
 *
 * @param templateElement - объект макета
 * @returns - React-компоненты дочерних элементов
 */
const CustomTemplate: React.FC<TCustomTemplate> = ({
  name,
  variables,
  variablesDepth,
  contexts,
  childrenPrototype,
}) => {
  const template = useSelector((state: RootState) => getTemplate(state, name));
  if (!template) return null;

  const templateContext = contexts?.template;

  const children = childrenPrototype
    ? _.isArray(childrenPrototype)
      ? childrenPrototype
      : [childrenPrototype]
    : [];

  const newVariablesData = updateElemVariables(
    { variables, variablesDepth },
    {
      variables: templateContext?.variables || {},
      variablesDepth: templateContext?.variablesDepth || {},
    }
  );

  const newContexts: TTemplateElemContext = {
    ...contexts,
    template: {
      name,
      path: [...(templateContext?.path || []), name || 'unknown'],
      variables: newVariablesData.variables,
      variablesDepth: newVariablesData.variablesDepth,
      children,
    },
  };

  return (
    <TemplateElements elements={template.template} contexts={newContexts} />
  );
};

export default CustomTemplate;
