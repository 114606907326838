import _ from 'lodash';
import { mutantTemplateElement } from './helpers/mutantTemplateElement';
import { templateElements } from './helpers/templateElements';
import { TStTemplateElem } from '../../../../../../../../Store/Slice/Site/types';
import { Mutator } from '../../../../../../../../Library/Mutator/Mutator';
import DefaultElement from './Components/DefaultElement';
import CustomElement from './Components/CustomElement';
import { useMutatorSelectors } from '../../../../../../../../Library/Mutator/Hooks/useMutatorSelectors';

const DynamicElement: React.FC<TStTemplateElem> = (templateElement) => {
  const mutationContexts = useMutatorSelectors(templateElement.contexts);
  const mutator = new Mutator(mutationContexts);

  const mutantElement = mutantTemplateElement(
    mutator,
    _.omit(templateElement, 'contexts') as TStTemplateElem,
    templateElement.contexts?.template?.path
  );

  if (!mutantElement || mutantElement.excluded) return null;

  const customElement = _.get(templateElements, mutantElement.tag || 'unknown');

  const domElement = customElement ? (
    <CustomElement
      {...{
        component: customElement,
        templateElement: {
          ...mutantElement,
          contexts: templateElement.contexts,
        },
      }}
    />
  ) : (
    <DefaultElement
      {...{ ...mutantElement, contexts: templateElement.contexts }}
    />
  );

  return <>{domElement}</>;
};

export default DynamicElement;
