import { denormalize } from 'normalizr';
import { RootState } from '../..';
import { TSelectorArg } from '../../types';
import filterStoreItems from '../Helpers/filterStoreItems';
import { cnFolder } from '../../NormSchema/cnFolder';
import _ from 'lodash';

export const getCnFolders = (state: RootState, args?: TSelectorArg[]) => {
  const collection = state.sliceContent.cnFolders;
  const filtered = args ? filterStoreItems(collection, args) : collection;
  if (!filtered) return;
  const wgGroupsNr = state.sliceWorkGroups.wgGroups;
  const usersNr = state.sliceUsers.users;
  const cnFolders = denormalize(Object.values(filtered), [cnFolder(1)], {
    cnFolders: collection,
    wgGroups: wgGroupsNr,
    users: usersNr,
  });
  return _.reduce(cnFolders, (r, v) => ({ ...r, [v.id]: v }), {});
};

export const getCnDocuments = (state: RootState, args?: TSelectorArg[]) => {
  const collection = state.sliceContent.cnDocuments;
  const filtered = args ? filterStoreItems(collection, args) : collection;
  if (!filtered) return;
  const wgGroupsNr = state.sliceWorkGroups.wgGroups;
  const usersNr = state.sliceUsers.users;
  const cnDocuments = denormalize(Object.values(filtered), [cnFolder(1)], {
    wgGroups: wgGroupsNr,
    users: usersNr,
  });
  return _.reduce(cnDocuments, (r, v) => ({ ...r, [v.id]: v }), {});
};
