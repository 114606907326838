import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TSliceUI } from './types';
import {
  getLsDataRequestSucceed,
  setInitialState,
} from '../../Saga/Initial/extraActions';
import { updateLSData } from '../Helpers/updateLSData';
import { TSiteActionProps } from '../../types';
import _ from 'lodash';
import { initialState } from './constants';

export const sliceUI = createSlice({
  name: 'ui',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(setInitialState, () => initialState);

    builder.addCase(getLsDataRequestSucceed, (state, { payload }) => {
      if (payload) {
        state.customStates = { ...state.customStates, ...payload.customStates };
        if (payload.meID) state.meID = payload.meID;
        if (payload.jwt) state.jwt = payload.jwt;
      }
    });
  },
  reducers: {
    setLocale: (state: TSliceUI, { payload }: PayloadAction<string>) => {
      state.locale = payload;
      updateLSData(state);
    },

    setMeID: (state: TSliceUI, { payload }: PayloadAction<string>) => {
      state.meID = payload;
      updateLSData({ ...state, jwt: undefined });
    },

    setJWT: (state: TSliceUI, { payload }: PayloadAction<string>) => {
      state.jwt = payload;
      updateLSData({ ...state, jwt: undefined });
    },

    setCustomState: (
      state,
      {
        payload,
      }: PayloadAction<{
        name: string;
        value?: TSliceUI['customStates'][''];
      }>
    ) => {
      _.set(state.customStates, payload.name, payload.value);
      updateLSData(state);
    },

    setNavigateTo: (
      state: TSliceUI,
      {
        payload,
      }: PayloadAction<
        | string
        | { url: string; action?: TSiteActionProps | TSiteActionProps[] }
        | undefined
      >
    ) => {
      if (_.isString(payload)) {
        state.navigateTo = {
          url: payload,
        };
      } else {
        state.navigateTo = payload;
      }
    },

    setNavigateBack: (state: TSliceUI) => {
      state.navigateBack = !state.navigateBack;
    },
  },
});

export const {
  setLocale,
  setMeID,
  setJWT,
  setCustomState,
  setNavigateTo,
  setNavigateBack,
} = sliceUI.actions;

export default sliceUI.reducer;
