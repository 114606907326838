/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from 'lodash';
import QueryString, { ParsedQs } from 'qs';
import { TSiteActionProps } from '../../../Store/types';
import store from '../../../Store';
import { setNavigateTo } from '../../../Store/Slice/UI';

const SetQuery = (arg?: TSiteActionProps['argument']) => {
  if (!arg || !_.isObject(arg)) {
    console.log('Argument must be { [param:string]: string } type');
    return;
  }

  const currentSearch: ParsedQs = QueryString.parse(
    window.location.search.replace(/^\?/, '')
  );

  const newSearchString = `?${QueryString.stringify(
    _.merge(currentSearch, arg)
  )}`;

  const url = window.location.pathname + newSearchString;
  store.dispatch(setNavigateTo({ url }));
};

export default SetQuery;
