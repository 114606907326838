export const TEMPLATE_STATIC = {
  HEAD_ELEMENTS: [
    {
      tag: 'meta',
      charSet: 'UTF-8',
    },
    {
      tag: 'meta',
      name: 'viewport',
      content: 'width=device-width, initial-scale=1',
    },
  ],
  BODY_ELEMENTS: [
    {
      tag: 'noscript',
      children: [
        {
          tag: 'p',
          children:
            'Для отображения сайта необходимо включить поддержку JavaScript.',
        },
        {
          tag: 'p',
          children: 'JavaScript support must be enabled to display the site.',
        },
      ],
    },
  ],
};
