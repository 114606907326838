import { createSlice } from '@reduxjs/toolkit';
import { TSliceWorkGroups } from './types';
import { setInitialState } from '../../Saga/Initial/extraActions';

export const initialState: TSliceWorkGroups = {
  wgGroups: {},
};

export const sliceWorkGroups = createSlice({
  name: 'workGroups',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(setInitialState, () => initialState);
  },
  reducers: {},
});

export default sliceWorkGroups.reducer;
