import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Page from './Components/Page';
import { RootState } from './Store';
import { getRqStatuses } from './Store/Slice/Requests/selectors';
import { getInitialRequest } from './Store/Saga/Initial/extraActions';
import { getSitePages } from './Store/Slice/Site/selectors';
import { TStPage } from './Store/Slice/Site/types';

/**
 *  Компонент приложения
 *
 * @returns приложение
 */
const App: React.FC = () => {
  const dispatch = useDispatch();

  const appLoadingStatus =
    useSelector((state: RootState) =>
      getRqStatuses(state, [{ name: 'id', value: 'init' }])
    )?.init?.status || 'initial';

  useEffect(() => {
    if (appLoadingStatus === 'initial') {
      dispatch(getInitialRequest());
    }
  }, [appLoadingStatus, dispatch]);

  const pages = useSelector(getSitePages);

  const errorPage = pages.find((page) => page.url === '/error') as TStPage;
  const loadingPage = pages.find((page) => page.url === '/loading') as TStPage;

  const getUndefinedPage = (): TStPage => {
    switch (appLoadingStatus) {
      case 'success':
        return {
          ...errorPage,
          url: '*',
          error: {
            code: 404,
          },
        };
      case 'initial':
      case 'progress':
        return {
          ...loadingPage,
          url: '*',
        };
      default:
        return {
          ...errorPage,
          url: '*',
          error: {
            code: 500,
          },
        };
    }
  };
  const undefinedPage = getUndefinedPage();

  const routes = [
    ...pages.filter((page) => page.url !== '/error' && page.url !== '/loading'),
    ...(undefinedPage ? [undefinedPage] : []),
  ].map((page) => ({
    id: page.id,
    path: page.url,
    element: <Page {...page} />,
  }));

  return <RouterProvider router={createBrowserRouter(routes)} />;
};

export default App;
