import _ from 'lodash';
import { TStTemplateElem } from '../../../../Store/Slice/Site/types';
import TemplateElement from './Components/TemplateElement';
import { TTemplateElemContext } from './Components/TemplateElement/types';

/**
 * Компонент для отображения массива корневых элементов макета (верхнего уровня)
 *
 * @param elements - массив элементов макета
 * @param schema - схема изменения массива
 *
 * @returns React-компоненты элементов макета
 */
const TemplateElements: React.FC<{
  elements: TStTemplateElem['children'];
  contexts: TTemplateElemContext;
}> = ({ elements, contexts }) => {
  if (!_.isObject(elements)) return null;
  return (
    <>
      {_.isArray(elements) ? (
        elements.map((templateItem, i) => {
          if (!_.isObject(templateItem)) return null;
          const itemProps: TStTemplateElem = {
            ...templateItem,
            contexts,
          };
          return <TemplateElement key={i} {...itemProps} />;
        })
      ) : (
        <TemplateElement {...{ ...elements, contexts }} />
      )}
    </>
  );
};

export default TemplateElements;
