import store from '../../../Store';
import { setInitialState } from '../../../Store/Saga/Initial/extraActions';
/**
 * выйти из профиля (разлогиниться)
 */
const LogOut = () => {
  store.dispatch(setInitialState());
};

export default LogOut;
