import LogOut from './LogOut';
import NavigateBack from './NavigateBack';
import NavigateTo from './NavigateTo';
import RedirectTo from './RedirectTo';
import ScrollTo from './ScrollTo';
import SetCustomState from './SetCustomState';
import SetMe from './SetMe';
import SetQuery from './SetQuery';
import SetToken from './SetToken';
import SwitchBooleanCustomState from './SwitchBooleanCustomState';
import Throw from './Throw';

const Action: Record<string, (a: any) => void> = {
  logOut: LogOut,
  navigateBack: NavigateBack,
  navigateTo: NavigateTo,
  redirectTo: RedirectTo,
  scrollTo: ScrollTo,
  setUIState: SetCustomState,
  setMe: SetMe,
  setQuery: SetQuery,
  setToken: SetToken,
  switchBooleanUIState: SwitchBooleanCustomState,
  throw: Throw,
};

export default Action;
