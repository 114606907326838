import _ from 'lodash';
import { TSliceUI } from '../../../Store/Slice/UI/types';
import { TBaseDataObject } from '../../../Store/types';
import store from '../../../Store';
import { setCustomState } from '../../../Store/Slice/UI';

type TArgument = {
  name: string;
  value?: TSliceUI['customStates'][''];
  timeout?: number;
};

const SetCustomState = (arg?: string | number | boolean | TBaseDataObject) => {
  if (!_.get(arg, 'name')) {
    console.log('Check argument format { name: string, value?: any }');
    return;
  }

  store.dispatch(setCustomState(arg as TArgument));
};

export default SetCustomState;
