import { useMutatorSelectors } from '../../../../../../../../../../Library/Mutator/Hooks/useMutatorSelectors';
import { TStTemplateElem } from '../../../../../../../../../../Store/Slice/Site/types';

const CustomContexts: React.FC<TStTemplateElem> = (props) => {
  const mutationContexts = useMutatorSelectors(props.contexts);
  console.log({ id: props.id, ...mutationContexts });
  return null;
};

export default CustomContexts;
