import { call, put } from 'redux-saga/effects';
import {
  getInitialRequestPending,
  getInitialRequestRejected,
  getInitialRequestSucceed,
} from '../Initial/extraActions';
import store from '../..';
import { TAbstractStore, TFetchResponse } from '../../types';
import { fetchAPI } from './Helpers/fetchAPI';
import { API_ENDPOINTS, API_URL_PREFIX } from '../../../Settings/api';
import constructPayload from './Helpers/constructPayload';

export function* getInitialData() {
  const status =
    store.getState().sliceRequests.rqStates?.initial?.status || 'initial';
  if (status !== 'initial') return;

  yield put(getInitialRequestPending());
  const responseObject: Partial<TFetchResponse> = yield call(() =>
    fetchAPI({
      url: API_URL_PREFIX + API_ENDPOINTS.init,
      method: 'GET',
      query: {
        locale: store.getState().sliceUI.locale,
      },
    })
  );

  if (responseObject.error) {
    yield put(getInitialRequestRejected(responseObject.error));
  } else {
    const responseData = constructPayload(
      responseObject.data as Record<string, TAbstractStore>
    );
    yield put(getInitialRequestSucceed(responseData));
  }
}
