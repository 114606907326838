import { call, take } from 'redux-saga/effects';
import { GET_INITIAL_REQUEST } from './extraActions';
import getLSData from '../Workers/getLocalStorage';
import { getInitialData } from '../Workers/getInitialData';

function* sagaInitial() {
  while (true) {
    yield take(GET_INITIAL_REQUEST);
    yield call(() => getLSData());
    yield call(() => getInitialData());
  }
}

export default sagaInitial;
